




import {
  onMounted,
  toRefs,
  ref,
  Ref,
  defineComponent,
  watch,
  computed,
  onBeforeUnmount
} from "@vue/composition-api"
import { store } from "@/store"

export default defineComponent({
  name: "ChimeStreamPlayer",
  props: {
    playbackUrl: {
      required: true,
      type: String
    }
  },
  emits: {
    refresh: null
  },
  setup(props, { emit }) {
    // why do I have to do this?
    const { playbackUrl } = toRefs(props) as { playbackUrl: Ref<string> }

    const element = ref(null)
    const player = ref(null)

    let timeout

    function canplay() {
      clearTimeout(timeout)
    }
    const volume = computed(() => store.getters["auth/volume"])
    watch(volume, v => {
      if (player.value) {
        player.value.setVolume(v)
      }
    })

    onMounted(() => {
      timeout = setTimeout(() => emit("refresh"), 5000)

      const script = document.createElement("script")

      script.src =
        "https://player.live-video.net/1.0.0/amazon-ivs-player.min.js"
      script.async = true

      document.body.appendChild(script)

      script.onload = () => {
        // @ts-ignore - IVSPlayer is defined globally
        if (IVSPlayer.isPlayerSupported) {
          // @ts-ignore - IVSPlayer is defined globally
          player.value = IVSPlayer.create()
          player.value.attachHTMLVideoElement(element.value)
          player.value.load(playbackUrl.value)
          player.value.setVolume(volume.value)
          player.value.play()
        }
      }
    })

    onBeforeUnmount(() => {
      player.value.pause()
      player.value.delete()
    })

    return { element, canplay }
  }
})
