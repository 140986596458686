




import { ref, defineComponent } from "@vue/composition-api"

import ChimeStreamPlayer from "./ChimeStreamPlayer.vue"

export default defineComponent({
  name: "ChimeStream",
  components: {
    ChimeStreamPlayer
  },
  props: {
    playbackUrl: {
      required: true,
      type: String
    }
  },
  setup(props, { emit }) {
    const key = ref(0)

    function refresh() {
      key.value = key.value + 1
    }

    return { refresh, key }
  }
})
