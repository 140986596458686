var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("ChimeStreamPlayer", {
    key: _vm.key,
    attrs: { playbackUrl: _vm.playbackUrl },
    on: { refresh: _vm.refresh },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }